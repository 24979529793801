<template>
  <div>
    <b-card class="text-center" style="cursor: pointer;">
      <b-card-title class="mt-2 mb-0">
        {{ $t('manageSchools.updateLicences.title') }}
      </b-card-title>
      <b-card-body v-if="!updateLicenceProcess">
        <b-card-text>
          {{ $t('manageSchools.updateLicences.click') }}
        </b-card-text>
        <b-button variant="primary" @click="updateLicencesProcess()">
          {{ $t('manageSchools.updateLicences.go_to') }}
        </b-button>
      </b-card-body>
      <b-card-body v-else>
        <!-- Fila de etiquetas (Profesores y Alumnos) -->
        <b-row>
          <b-col md="2" class="text-left">
            <h5>{{ $t('manageSchools.updateLicences.teachers') }}</h5>
          </b-col>
        </b-row>

        <!-- Fila de selectores y botones para Profesores -->
        <b-row class="align-items-center ml-3">
          <b-col md="4">
            <b-form-group :label="$t('manageSchools.updateLicences.initial_date')">
              <b-form-datepicker v-model="startDateTeachers" :min="minValidFrom" :max="maxValidUntil"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('manageSchools.updateLicences.final_date')">
              <b-form-datepicker v-model="endDateTeachers" :min="minValidFrom" :max="maxValidUntil"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-button variant="primary" class="w-100" @click="updateTeachersLicences"
              :disabled="!startDateTeachers || !endDateTeachers || updatedTeachers" v-if="!updatedTeachers">
              {{ $t('manageSchools.updateLicences.update') }}
            </b-button>
            <b-button variant="success" class="w-100"
              :disabled="updatedTeachers" v-else>
                {{ $t('manageSchools.updateLicences.updated') }}
            </b-button>
          </b-col>
        </b-row>

        <!-- Fila de selectores y botones para Alumnos -->
        <b-row class="mt-2">
          <b-col md="2" class="text-left">
            <h5 class="mb-0">{{ $t('manageSchools.updateLicences.students') }}</h5>
          </b-col>
        </b-row>
        <b-row class="align-items-center ml-3">
          <b-col md="4">
            <b-form-group label="Fecha de Inicio">
              <b-form-datepicker v-model="startDateStudents" :min="minValidFrom" :max="maxValidUntil"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Fecha de Fin">
              <b-form-datepicker v-model="endDateStudents" :min="minValidFrom" :max="maxValidUntil"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-button variant="primary" class="w-100" @click="updateStudentsLicences"
              :disabled="!startDateStudents || !endDateStudents || updatedStudents" v-if="!updatedStudents">
              {{ $t('manageSchools.updateLicences.update') }}
            </b-button>
            <b-button variant="success" class="w-100"
              :disabled="updatedStudents" v-else>
              {{ $t('manageSchools.updateLicences.updated') }}
            </b-button>
          </b-col>
        </b-row>

        <!-- Botón para cerrar -->
        <b-row>
          <b-col class="d-flex justify-content-center mt-2">
            <b-button variant="secondary" class="w-25" @click="updateLicencesProcess()">
              {{ $t('manageSchools.updateLicences.close') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BButton, BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import { fetchSchool, updateSchoolLicences } from '@/api/routes'


export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BFormGroup,
    BFormDatepicker,
    CustomBreadcrumb
  },
  props: {
    school: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updateLicenceProcess: false,
      startDateTeachers: null,
      endDateTeachers: null,
      startDateStudents: null,
      endDateStudents: null,
      maxValidUntil: null,
      minValidFrom: null,
      SchoolAdminInfo: false,
      updatedTeachers: false,
      updatedStudents: false,
    }
  },
  created() {
  },
  methods: {
    async getSchoolAdminInfo() {
      let data = {
        id: this.school.id
      }
      await this.$http.post(fetchSchool, data).then(response => {
        const payments = response.data.school.payments;
        this.SchoolAdminInfo = response.data.school.admin;
        const validPayments = payments.filter(payment => payment.deleted_at === null);
        if (validPayments.length > 0) {
          let goodPayment = validPayments.reduce((max, payment) => {
            return payment.valid_until > max ? payment.valid_until : max;
          }, validPayments[0]);
          this.maxValidUntil = goodPayment.valid_until;
          this.minValidFrom = goodPayment.valid_from;
        }
      }).catch(error => {
        this.makeToast('danger', 'Error', 'Hubo un problema al obtener la información del colegio.');
      });
    },
    updateLicencesProcess() {
      this.updateLicenceProcess = !this.updateLicenceProcess;
      this.updatedTeachers = false;
      this.updatedStudents = false;
      this.startDateTeachers = null;
      this.endDateTeachers = null;
      this.startDateStudents = null;
      this.endDateStudents = null;
      if (this.SchoolAdminInfo === false) this.getSchoolAdminInfo();
    },
    async updateTeachersLicences() {
      const data = {
        valid_from: this.startDateTeachers,
        valid_until: this.endDateTeachers,
        school_id: this.school.id,
        context: "teachers"
      };
      await this.$http.post(updateSchoolLicences, data).then(response => {
        this.makeToast('success', 'Success', 'Licences updated successfully.')
      }).catch(error => {
        this.makeToast('danger', 'Error', 'Error during licence updating.')
      })
      this.updatedTeachers = true;
    },
    async updateStudentsLicences() {
      const data = {
        valid_from: this.startDateStudents,
        valid_until: this.endDateStudents,
        school_id: this.school.id,
        context: "students"
      };
      await this.$http.post(updateSchoolLicences, data).then(response => {
        this.makeToast('success', this.$t('manageSchools.updateLicences.success'), this.$t('manageSchools.updateLicences.update_success'))
      }).catch(error => {
        this.makeToast('danger', this.$t('manageSchools.updateLicences.error'), this.$t('manageSchools.updateLicences.update_error'))
      })
      this.updatedStudents = true;
    },

    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    }
  },
}
</script>