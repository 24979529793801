<template>
  <div>
    <custom-breadcrumb :pageTitle="$t('manageSchools.manageSchool.title')" :breadcrumb="[
      {
        text: $t('Schools'),
        to: 'schools'
      },
      {
        text: school.name,
        to: 'school-view',
        params: { id: school.id }
      },
      {
        text: $t('manageSchools.manageSchool.handle'),
        active: true,
      },
    ]"></custom-breadcrumb>
    <b-container>
      <b-row>
        <b-col md="6" v-if="!isEducamadrid()">
          <b-card class="text-center" style="cursor: pointer;">
            <b-card-title>
              {{$t('manageSchools.manageSchool.upload_structure')}}
            </b-card-title>
            <b-card-text>
              {{ $t('manageSchools.manageSchool.click') }}
            </b-card-text>
            <b-button variant="primary" @click="navigateTo('school-upload')"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              {{$t('manageSchools.manageSchool.go_to')}}
            </b-button>
          </b-card>
        </b-col>
        <b-col v-else>
          <b-card class="text-center" style="cursor: pointer;">
            <b-card-title>
              {{$t('manageSchools.raices.title')}}
            </b-card-title>
            <b-card-text>
              {{ $t('manageSchools.raices.click') }}
            </b-card-text>
            <b-button variant="primary" @click="navigateTo('raices')"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              {{$t('manageSchools.raices.go_to')}}
            </b-button>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="text-center" style="cursor: pointer;">
            <b-card-title>
              {{$t('manageSchools.deleteStructure.title')}}
            </b-card-title>
            <b-card-text>
              {{ $t('manageSchools.deleteStructure.click') }} 
            </b-card-text>
            <b-button variant="danger" @click="navigateTo('school-delete')"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
              {{$t('manageSchools.deleteStructure.go_to')}}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.$store.getters['user/getRole'] !== 'school-admin'">
        <b-col md=12>
          <update
            :school="school"
          />
        </b-col>     
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BButton, BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import Update from './Update.vue';

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BFormGroup,
    BFormDatepicker,
    CustomBreadcrumb,
    Update
  },
  data() {
    return {
      school: {},
      updateLicenceProcess: false,
      startDateTeachers: null,
      endDateTeachers: null,
      startDateStudents: null,
      endDateStudents: null
    }
  },
  created() {
    this.getSchool();
    if (this.$store.getters['user/getRole'] === 'school-admin') {
      const school = this.$store.getters['user/isSchool'];
      if (school && school.id != this.$route.params.id) {
        this.navigateTo('school-manage');
      }
    } else if (!this.school.id || this.school.id === 0) {
      this.navigateTo('schools');
    }
  },
  methods: {
    getSchool() {
      if (this.$store.getters['user/getRole'] == 'school-admin') this.school = this.$store.getters['user/isSchool'];
      else {
        this.school.id = this.$route.params.id;
        this.school.name = this.$t('Detail');
      }
    },
    navigateTo(page) {
      const id = this.school.id;
      this.$router.push({ name: `${page}`, params: { id } })
    },
  },
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>