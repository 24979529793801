var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-breadcrumb',{attrs:{"pageTitle":_vm.$t('manageSchools.manageSchool.title'),"breadcrumb":[
    {
      text: _vm.$t('Schools'),
      to: 'schools'
    },
    {
      text: _vm.school.name,
      to: 'school-view',
      params: { id: _vm.school.id }
    },
    {
      text: _vm.$t('manageSchools.manageSchool.handle'),
      active: true,
    } ]}}),_c('b-container',[_c('b-row',[(!_vm.isEducamadrid())?_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"text-center",staticStyle:{"cursor":"pointer"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.manageSchool.upload_structure'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.manageSchool.click'))+" ")]),_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.navigateTo('school-upload')}}},[_vm._v(" "+_vm._s(_vm.$t('manageSchools.manageSchool.go_to'))+" ")])],1)],1):_c('b-col',[_c('b-card',{staticClass:"text-center",staticStyle:{"cursor":"pointer"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.raices.title'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.raices.click'))+" ")]),_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.navigateTo('raices')}}},[_vm._v(" "+_vm._s(_vm.$t('manageSchools.raices.go_to'))+" ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"text-center",staticStyle:{"cursor":"pointer"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.deleteStructure.title'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('manageSchools.deleteStructure.click'))+" ")]),_c('b-button',{attrs:{"variant":"danger","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.navigateTo('school-delete')}}},[_vm._v(" "+_vm._s(_vm.$t('manageSchools.deleteStructure.go_to'))+" ")])],1)],1)],1),(this.$store.getters['user/getRole'] !== 'school-admin')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('update',{attrs:{"school":_vm.school}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }